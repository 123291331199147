// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mfe-beh-smart-groups-error-state {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.mfe-beh-smart-groups-error-msg {
  width: 100%;
}

.mfe-beh-smart-groups-error-msg h1 {
  color: var(--text-color-dark, #333) !important;
}`, "",{"version":3,"sources":["webpack://./common/components/error-state/error-state.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;AACJ;;AAEA;EACI,WAAA;AACJ;;AAEA;EACI,8CAAA;AACJ","sourcesContent":[".__mfe__error-state {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    width: 100%;\r\n}\r\n\r\n.__mfe__error-msg {\r\n    width: 100%;\r\n}\r\n\r\n.__mfe__error-msg h1 {\r\n    color: var(--text-color-dark, #333) !important;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
