// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mfe-beh-smart-groups-smgan__dialog_body_wrapper {
  min-width: 688px;
}

.mfe-beh-smart-groups-smgan__notes_length {
  margin-top: 2px;
  display: flex;
  justify-content: flex-end;
}

.mfe-beh-smart-groups-smgan__notes_length span {
  color: var(--text-color-light, #677274);
  font: var(--neon-2_8_0-font-1);
}

.mfe-beh-smart-groups-smgan__student_length {
  margin-bottom: 2px;
}

.mfe-beh-smart-groups-smgan__student_length span {
  color: var(--text-color-dark, #333);
  font: var(--neon-2_8_0-font-1);
}`, "",{"version":3,"sources":["webpack://./components/smart-groups/smart-group-table/smart-group-add-notes/smart-group-add-notes.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;;AACA;EACI,eAAA;EACA,aAAA;EACA,yBAAA;AAEJ;;AAAA;EACI,uCAAA;EACA,8BAAA;AAGJ;;AADA;EACI,kBAAA;AAIJ;;AAFA;EACI,mCAAA;EACA,8BAAA;AAKJ","sourcesContent":[".__mfe__smgan__dialog_body_wrapper{\r\n    min-width: 688px;\r\n}\r\n.__mfe__smgan__notes_length{\r\n    margin-top: 2px;\r\n    display: flex;\r\n    justify-content: flex-end;\r\n}\r\n.__mfe__smgan__notes_length span{\r\n    color: var(--text-color-light, #677274);\r\n    font: var(--__neon__font-1);\r\n}\r\n.__mfe__smgan__student_length{\r\n    margin-bottom: 2px;\r\n}\r\n.__mfe__smgan__student_length span{\r\n    color: var(--text-color-dark, #333);\r\n    font: var(--__neon__font-1);\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
