// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mfe-beh-smart-groups-loading-state {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font: var(--neon-2_8_0-font-3);
  gap: var(--neon-2_8_0-spacing-x-large);
  margin: var(--neon-2_8_0-spacing-x-large);
}

.mfe-beh-smart-groups-loading-text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./common/components/loading-state/loading-state.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;EACA,8BAAA;EACA,sCAAA;EACA,yCAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;AACJ","sourcesContent":[".__mfe__loading-state {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    flex-direction: column;\r\n    font: var(--__neon__font-3);\r\n    gap: var(--__neon__spacing-x-large);\r\n    margin: var(--__neon__spacing-x-large);\r\n}\r\n\r\n.__mfe__loading-text {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    flex-direction: column;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
